import { useEffect, useState } from "react";
import DotsLoader from "../../components/DotsLoader";
// import topSectionIll from "../../assets/top-section-bg.png";
import testnet from "../../assets/testnet-transaction.png";
import users from "../../assets/testnet-users.png";
import syCreditsService from "../../services/syCredits";
import CountUp from "react-countup";

import Eth from "../../assets/chains/syETH.png";
import Bnb from "../../assets/chains/syBNB.png";
import Polygon from "../../assets/chains/syMATIC.png";
import Arbitrum from "../../assets/chains/ARBITRIUM SEPOLIA.png";
import Avax from "../../assets/chains/syAVAX.png";
import Sei from "../../assets/chains/SEI.png";
import SyMetis from "../../assets/chains/syMETIS.png";
import Sui from "../../assets/chains/SUI.png";
import Chain9 from "../../assets/chains/ARROOW.png";
import useIsMobile from "./Common/useIsMobile";

const Chains = [Eth, Bnb, Avax, Polygon, Arbitrum, Chain9, SyMetis, Sei, Sui]

const TopSection = () => {

  const [totalTnx, setTotalTnx] = useState(null);
  const [totalTestnetUser, setTotalTestnetUser] = useState(null);
  // const [totalTransaction, setTotalTransaction] = useState(0);
  const isMobile = useIsMobile();

  // useEffect(() => {
  //   const title = document.querySelectorAll(".text-title-animation");
  //   title.forEach((ele) => {
  //     inView(ele, () => {
  //       addAnimation(ele)
  //       return () => addAnimation(ele,true);
  //     })
  //   })
  // })

  const addAnimation = (ele, stop) => {
    const spanTags = ele.querySelectorAll("span")
    spanTags.forEach(spanTag => {
      if (stop) {
        spanTag.classList.remove("text-animation");
      } else {
        spanTag.classList.add("text-animation");
      }
    });
  }

  const handleOpenApp = () => {
    window.open("https://mvp.synthr.io", "_blank")
  }

  const getTotalTnxCount = async () => {
    await syCreditsService.getTotalTransaction().then((res) => {
      const data = res.data;
      if(data.success){
        setTotalTnx(data.data);
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const getTotalTestnetuser = async () => {
    await syCreditsService.getTotalTestnetUser().then((res) => {
      const data = res.data;
      if(data.success){
        const details = data.data;
        const totalWallets = details.total_wallets
        setTotalTestnetUser(totalWallets);
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    getTotalTestnetuser();
    getTotalTnxCount();
  }, [])


  return(
    <section className='top-section'>
      <div className='site-container'>
        <div className='top-section-wrapper'>
          <div className='title'>
            {/* <AnimatedText data="INTEROPERABLE"/> */}
            INTEROPERABLE
            <br />
            OMNICHAIN {' '}
            {isMobile ? <br/> : ""}
            LIQUIDITY
          </div>
          
          <div className='description'>
            Chain-free travel across the <span>DeFiverse.</span>
          </div>
        </div>
        
        <div className='d-md-flex align-items-center matrix-wrapper'>
          
          <div className='matrix-box box-1 d-flex align-items-center'>
            {/* <img className="testnetImg" src={testnet} alt='' /> */}
            <div>
                <div className='value'>
                {
                totalTnx !== null ? 
                  totalTnx?.total ? (totalTnx.total).toLocaleString() : (9598349).toLocaleString()
                :
                  <DotsLoader />
                }
              </div>
              <div className='label'> Total transactions </div>
            </div>
          </div>
          <div className='matrix-box box-2 d-flex align-items-center'>
            {/* <img className="testnetImg" src={users} alt='' /> */}
            <div>
              <div className='value'> 
                {
                  totalTestnetUser !== null ?
                    (totalTestnetUser).toLocaleString()
                  :
                    <DotsLoader />
                }
              </div>
              <div className='label'> Total users </div>
            </div>              
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <button 
              className='launch-button'
              onClick={handleOpenApp}
            >
              LAUNCH APP
            </button>
          </div>
          
        </div>
        <div className="build-currency-sec">
          <span className="bcs-heading">BEING BUILT ON</span>
          <div className='d-flex flex-wrap grants'>
              {Chains.map((chain, ind) => 
                <img src={chain} alt='' key={ind} />
              )}
            </div>
        </div>
      </div>
    </section>
  )
}
export default TopSection;