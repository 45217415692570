import {ReactComponent as GoldMedal} from "../../assets/goldMedal.svg";
import {ReactComponent as SilverMedal} from "../../assets/silverMedal.svg";
import {ReactComponent as BronzeMedal} from "../../assets/bronzeMedal.svg";
import {ReactComponent as UpDownArrows} from "../../assets/up-down-arrows.svg";
import { useState } from "react";

const ZealyLeaderboard = ({users, leaderboard, updatedDateAndTime, setUsers, loading, showNavbar}) => {
  const [sortOrder, setSortOrder] = useState({ zealyPoints: true })

  const shortAddress = (address) => {
    const AddressLength = address.length;
    const last4LetterInd = AddressLength - 4;
    const first4Letters = AddressLength > 4 ? address.substring(0, 4) : address;
    const lastLetters = AddressLength > 4 ? address.substring(last4LetterInd, AddressLength) : address;
    return `${first4Letters}....${lastLetters}`;
  }

  const title = (title, sortKey) => {
    return(
      <div className="d-flex align-items-center">
        {title}   
        <div className='up-down-arrows' onClick={() => changeOrder(sortKey)}> <UpDownArrows /> </div>        
      </div>
    )
  }

  const changeOrder = (sortKey) => {
    const newUser = [...users];
    if(sortKey === "rank" || sortKey === "Zealy XP"){
      if(sortOrder.zealyPoints){
        newUser.sort((a, b) => a.xp - b.xp)
        setSortOrder({...sortOrder, zealyPoints: false})
      }
      else{
        newUser.sort((a, b) => b.xp - a.xp)
        setSortOrder({...sortOrder, zealyPoints: true})
      }
    }
    setUsers(newUser)
  }


  return(
    <div className="leaderborad-wrapper">
      <div className={`d-md-flex align-items-center justify-content-between title-container ${!showNavbar ? 'ps-0' : '' }`}>
        <div className="title"> Zealy XP leaderboard </div>
        {
          updatedDateAndTime &&
          <div className="update-date-and-time">Next update on: {updatedDateAndTime}</div>
        }
      </div>
      {
        loading ? 
          <div className="empty-leaderboard-text">Loading...</div>
        :
        users !== null ?
          <div className="table-wrapper">
            <table className="leaderboard-table zealy-table">
              <thead>
                <tr>
                  <td className={`${!showNavbar ? 'ps-0' : '' } heading-black`}> 
                    {title("Rank", "rank")}
                  </td>
                  <td className="heading-black"> 
                    User 
                  </td>
                  
                  <td className="heading-green">{title("Zealy XP", "Zealy XP")}</td>
                </tr>
              </thead>

              <tbody>
                {
                  users.length > 0 &&
                  users.map((item, index) => (
                    <tr key={`${item.discord_tag}-${index}`}>
                      <td className={`${!showNavbar ? 'ps-0' : '' }`}>
                        {users.length === 1 ? <div>-</div> : 
                          (index === 0 ? <GoldMedal /> : index === 1 ? <SilverMedal /> : index === 2 ? <BronzeMedal /> : index + 1)
                        }
                      </td>
                      <td>{item.discordHandle ? item.discordHandle : shortAddress(item.connectedWallet)}</td>
                      <td>{item.xp ? item.xp.toLocaleString('en-US') : 0}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          :
          <div className="empty-leaderboard-text">
            There is no current Zealy leaderboard.
          </div>
      }
    </div>
  )
}
export default ZealyLeaderboard;