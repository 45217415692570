import { useEffect, useState, useRef } from "react";
import syCreditsService from "../../services/syCredits";

import Leaderboard from "../../components/SyCredits/Leaderboard";
import TransactionHistory from "../../components/SyCredits/TransactionHistory";
import Footer from "../../components/NewFooter";

import {ReactComponent as LeaderboardIcon} from "../../assets/leaderboard.svg";
import {ReactComponent as TransactionHistoryIcon} from "../../assets/transactionHistory.svg";
import {ReactComponent as ZealyLeaderboardIcon} from "../../assets/zealy-leaderboard.svg";

import TwitterCard from "../../components/TwitterCard";
// import { toPng } from "html-to-image";
import ZealyLeaderboard from "../../components/SyCredits/ZealyLeaderboard";
import Cross from "../../assets/cross.svg";
import domtoimage from 'dom-to-image';
// import {ReactComponent as LogsIcon} from "../../assets/logs.svg";
// import { ReactComponent as ReferralsIcon } from "../../assets/referrals-icon.svg";
// import {ReactComponent as CampaignLeaderboardIcon} from "../../assets/campaignLeaderboard.svg";
// import TransactionHistory from "../../components/SyCredits/TransactionHistory";
// import Logs from "../../components/SyCredits/Logs";
// import top_users from "./top_users";
// import Referrals from "../../components/SyCredits/Referrals";

const TableNavbar = [ 
  "syCREDITS leaderboard", 
  'Zealy XP leaderboard',
  'Transaction history',
  // "Campaign Leaderboard", 
  // 'Referrals',
  // "Logs",
]

const chains = ['All', 'Arbitrum Goerli', 'Arbitrum Sepolia', 'Avalanche Fuji', 'BNB Testnet', 'Ethereum Goerli',  'Ethereum Sepolia', 'Metis Goerli', 'Polygon Amoy', 'Polygon Mumbai']

const TableSection = () => {
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("syCREDITS leaderboard");
  const [leaderboardTopUsers, setLeaderboardTopUsers] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [filteredTransactionHistory, setFilteredTransactionHistory] = useState([]);
  const [filtredTopUsers, setFilteredTopUser] = useState([]);
  const [currentDateAndTimeForLeaderboard, setCurrentDateAndTimeForLeaderBoard] = useState('');
  const [timeForCard, setTimeForCard] = useState('');
  const elementRef = useRef(null);
  const [visibleCard, setVisibleCard] = useState(false);
  const [chain, setChain] = useState('All');
  const [leaderboardLoading, setLeaderboardLoading] = useState(false);
  const [totalTnx, setTotalTnx] = useState(null);
  const [totalTestnetUser, setTotalTestnetUser] = useState(null);
  const [zealyTopUsers, setZealyTopUsers] = useState([]);
  const [zealyLeaderboardLoading, setZealyLeaderboardLoading] = useState(false);
  const [showTableNavbar, setShowTableNavbar] = useState(false);
  const [showTabs, setShowTabs] = useState(false);
  // const [referrals, setReferrals] = useState([]);
  // const [referralsLoader, setReferralsLoader] = useState(false)
  // const [campaignTopUsers, setCampaignTopUsers] = useState(null);
  // const [campaignFilteredTopUser, setCampaignFilteredTopUser] = useState(null);
  // const [logs, setLogs] = useState([]);
  // const [logsLoader, setLogsLoader] = useState(false);
  // const [apiCallCount, setApiCallCount] = useState(0);
  // const [filteredLogs, setFilteredLogs] = useState([]);
  // const [currentDateAndTimeForLogs, setCurrentDateAndTimeForLogs] = useState('');

  const getCurrentDateTime = () => {
    const now = new Date();
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const month = monthNames[now.getUTCMonth()];
    const day = now.getUTCDate();
    const year = now.getUTCFullYear();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();

    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    const formattedDate = `${String(day).padStart(2, '0')} ${month} ${year} | ${String(formattedHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${ampm} UTC`;
    return formattedDate;
  }

  useEffect(() => {
    getLeaderboard();
    
    getZealyLeaderboard();
  }, [])
  
  const onHandleSearch = (value) => {
    setSearchValue(value)
    if(activeTab === "syCREDITS leaderboard" || activeTab === 'Transaction history'){
      if(value !== ''){
        setLeaderboardLoading(true);
        syCreditsService.SearchUser(value).then((res) => {
          if(res.data.success){
            const data = res.data.data;
            if(data?.user){
              const user = [];
              user.push(data.user);
              const newUser = allValues(user);
              changeTnxHistory(newUser)
              setFilteredTransactionHistory(newUser);
              setFilteredTopUser(newUser)
            }
          }
          else{
            setFilteredTopUser(leaderboardTopUsers)
            setFilteredTransactionHistory(transactionHistory)
          }
        })
        .catch((err) => {
          console.log(err);
        })
        setLeaderboardLoading(false)
      }
      else{
        setFilteredTopUser(leaderboardTopUsers)
        setFilteredTransactionHistory(transactionHistory)
      }

      if(activeTab === "syCREDITS leaderboard" && (!totalTestnetUser || !totalTnx)){
        getTotalTestnetuser();
        getTotalTnxCount();
      }
    }
    // else if(activeTab === "Campaign Leaderboard"){
    //   if(value !== ''){
    //     syCreditsService.SearchUser(value).then((res) => {
    //       if(res.data.success){
    //         const data = res.data.data;
    //         if(data?.user){
    //           const user = [];
    //           user.push(data.user);
    //           const newUsers = user.map((item, ind) =>  {
    //             const totalTnx = item.num_burn_same + item.num_mint_same + item.num_swap_same + item.num_addcol_same + item.num_mint_cross + item.num_swap_cross + item.num_liquid_position + item.num_liquid_flag + item.num_bridge + item.num_withdraw;
    //             const mintTnx = item.num_mint_cross + item.num_mint_same;
    //             const swapTnx = item.num_swap_cross + item.num_swap_same;
    //             return { ...item, ind: ind, transactions: totalTnx, mintTnx, swapTnx }
    //           })
    //           setCampaignFilteredTopUser(newUsers)
    //         }
    //       }
    //       else{
    //         setCampaignFilteredTopUser(campaignTopUsers)
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    //   }
    //   else{
    //     setCampaignFilteredTopUser(campaignTopUsers)
    //   }
    // }
    // else if(activeTab === 'Logs'){
    //   const cloneArray = [...logs];
    //   const filtered = cloneArray.filter((obj) => {
    //     return (
    //       obj.wallet_address.toLowerCase().includes(value.toLowerCase()) 
    //       // || obj.discord_tag.toLowerCase().includes(value.toLowerCase())
    //     );
    //   });
    //   if(filtered.length !== 0 && value !== ""){
    //     setFilteredLogs(filtered);
    //   }
    //   else if(value !== ""){
    //     setFilteredLogs([]);
    //   }
    //   else{
    //     setFilteredLogs(logs)
    //   }
    // }
    // if(activeTab === "Referrals"){
    //   getReferrals()
    // }
  }

  const getLeaderboard = async () => {
    setLeaderboardLoading(true)
    await syCreditsService.getLeaderboard().then((res) => {
      const data = res?.data;
      if(res.status === 200 && data.success){
        const top_users = data?.data?.top_users;
        const newUsers = allValues(top_users);
        
        if(newUsers.length > 0) {
          setLeaderboardTopUsers(newUsers); 
          setFilteredTopUser(newUsers);
          setTransactionHistory(newUsers);
          setFilteredTransactionHistory(newUsers);
          const updatedDateAndTime = newUsers[0].updatedAt;
          updateDateAndTime(updatedDateAndTime);
        }
      }
    })
    .catch((err) => {
      alert(err);
    });
    setLeaderboardLoading(false)
  }

  const getZealyLeaderboard = async () => {
    setZealyLeaderboardLoading(true)
    await syCreditsService.zealyLeaderboard().then((res) => {
      
      const data = res?.data?.data;
      if(res.status === 200 && data.length > 0){
        const top50Users = data.slice(0, 50)
        setZealyTopUsers(top50Users);
      }
    })
    .catch((err) => {
      alert(err);
    });

    setZealyLeaderboardLoading(false)
  }



  const updateDateAndTime = (dateAndTime) => {
    const date = new Date(dateAndTime);
    date.setHours(date.getHours() + 24);
    
    const formattedDate = date.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      timeZone: 'UTC'
    }) + " UTC";
    setCurrentDateAndTimeForLeaderBoard(formattedDate)
  }

  const generateImage = async () => {
    const currentDateAndTime = getCurrentDateTime();
    setTimeForCard(currentDateAndTime);
    
    try {
      const dataUrl1 = await domtoimage.toPng(elementRef.current);
      const dataUrl = await domtoimage.toPng(elementRef.current);
      const link = document.createElement('a');
      link.download = 'TESTNET-STATS.png';
      link.href = dataUrl;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the link element after the download
    } catch (error) {
      console.error('Failed to capture the screenshot', error);
    }

    setVisibleCard(false)
  }

  const getTotalTnxCount = async () => {
    await syCreditsService.getTotalTransaction().then((res) => {
      const data = res.data;
      if(data.success){
        const total = data.data.total
        setTotalTnx(total);
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const getTotalTestnetuser = async () => {
    await syCreditsService.getTotalTestnetUser().then((res) => {
      const data = res.data;
      if(data.success){
        const details = data.data;
        const totalWallets = details?.total_wallets;
        setTotalTestnetUser(totalWallets);
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    changeTnxHistory(filteredTransactionHistory);
  }, [chain])

  const changeTnxHistory = (users) => {
    if(users !== null){
      switch (chain) {
        case 'All':
          const newUsers = allValues(users);
          setFilteredTransactionHistory(newUsers);
          break;
        case 'Arbitrum Goerli':
          const arbitrumUsers = arbitrumGoerliValues(users);
          setFilteredTransactionHistory(arbitrumUsers);
          break;
        case 'BNB Testnet':
          const BSCTestnetUsers = BSCTestnetValues(users) 
          setFilteredTransactionHistory(BSCTestnetUsers);
          break;
        case 'Avalanche Fuji':
          const FujiUsers = FujiChainValues(users)
          setFilteredTransactionHistory(FujiUsers);
          break;
        case 'Ethereum Goerli':
          const goerliTestnetUsers = goereliTestnetValues(users) 
          setFilteredTransactionHistory(goerliTestnetUsers);
          break;
        case 'Polygon Mumbai':
          const polygonMumbaiUsers = polygonMumbaiValues(users)
          setFilteredTransactionHistory(polygonMumbaiUsers);
          break;
        case 'Metis Goerli':
          const MetisUser = metisValues(users);
          setFilteredTransactionHistory(MetisUser);
          break;
        case 'Ethereum Sepolia':
          const SepoliaUser = sepoliaValues(users); 
          setFilteredTransactionHistory(SepoliaUser);
          break;
        case 'Arbitrum Sepolia':
          const ArbitrumSepolia = ArbitrumSepoliaValues(users); 
          setFilteredTransactionHistory(ArbitrumSepolia);
          break;
        case 'Polygon Amoy':
          const PolygonAmoy = PolygonAmoyValues(users); 
          setFilteredTransactionHistory(PolygonAmoy);
          break;
        default:
          const allUsers = allValues(users); 
          setFilteredTransactionHistory(allUsers);
          break;
      }
    }
  }

  const allValues = (users) => {
    const newUser = users.map((item, ind) =>  {
      const addCollateral = item.arbgoerli_stake + item.arbsepolia_stake  + item.bsc_stake + item.fuji_stake + item.goerli_stake + item.metis_stake + item.mumbai_stake + item.sepolia_stake + item.amoy_stake;
      const mintTnx = item.arbgoerli_mint + item.arbsepolia_mint + item.bsc_mint + item.fuji_mint + item.goerli_mint + item.mumbai_mint + item.sepolia_mint + item.amoy_mint;
      const sameChainSwap = item.arbgoerli_swap_same + item.arbsepolia_swap_same + item.bsc_swap_same + item.fuji_swap_same + item.goerli_swap_same + item.metis_swap_same + item.mumbai_swap_same + item.sepolia_swap_same + item.amoy_swap_same + item.arbgoerli_swap_cross + item.arbsepolia_swap_cross + item.bsc_swap_cross + item.fuji_swap_cross + item.goerli_swap_cross + item.metis_swap_cross + item.mumbai_swap_cross + item.sepolia_swap_cross + item.amoy_swap_cross;    
      const bridge = item.arbgoerli_bridge + item.arbsepolia_bridge + item.bsc_bridge + item.fuji_bridge + item.goerli_bridge + item.metis_bridge + item.mumbai_bridge + item.sepolia_bridge + item.amoy_bridge;
      const burn = item.arbgoerli_burn + item.arbsepolia_burn + item.bsc_burn + item.fuji_burn + item.goerli_burn + item.metis_burn + item.mumbai_burn + item.sepolia_burn + item.amoy_burn;
      const withdrawCollateral = item.arbgoerli_collateral + item.arbsepolia_collateral + item.bsc_collateral + item.fuji_collateral + item.goerli_collateral + item.mumbai_collateral + item.sepolia_collateral + item.amoy_collateral;
      const flagForLiquidation = item.arbgoerli_liquidation_flag + item.arbsepolia_liquidation_flag + item.bsc_liquidation_flag + item.fuji_liquidation_flag + item.goerli_liquidation_flag + item.mumbai_liquidation_flag + item.sepolia_liquidation_flag + item.amoy_liquidation_flag;
      const liquidate = item.arbgoerli_liquidate + item.arbsepolia_liquidate + item.bsc_liquidate + item.fuji_liquidate + item.goerli_liquidate + item.mumbai_liquidate + item.sepolia_liquidate + item.amoy_liquidate;
      const escrowRewards = item.arbgoerli_escrow + item.arbsepolia_escrow;
      const withdrawRewards = item.arbgoerli_withdraw + item.arbsepolia_withdraw;
      const campRewards = item.camp_reward;
      const totalTnx = bridge + burn + addCollateral + mintTnx + sameChainSwap + withdrawCollateral + flagForLiquidation + liquidate + escrowRewards + withdrawRewards;
      
      return { ...item, ind: ind, transactions: totalTnx, campRewards, mintTnx, sameChainSwap, addCollateral, bridge, burn, withdrawCollateral, flagForLiquidation, liquidate, escrowRewards, withdrawRewards }
    })
    return newUser;
  }

  const arbitrumGoerliValues = (users) => {
    const newUser = users.map((item, ind) =>  {
      const campRewards = item.camp_reward;
      const addCollateral = item.arbgoerli_stake;
      const mintTnx = item.arbgoerli_mint;
      const sameChainSwap = item.arbgoerli_swap_same + item.arbgoerli_swap_cross;
      const bridge = item.arbgoerli_bridge;
      const burn = item.arbgoerli_burn;
      const withdrawCollateral = item.arbgoerli_collateral;
      const flagForLiquidation = item.arbgoerli_liquidation_flag;
      const liquidate = item.arbgoerli_liquidate;
      const escrowRewards = item.arbgoerli_escrow;
      const withdrawRewards = item.arbgoerli_withdraw;
      const totalTnx = bridge + burn + addCollateral + mintTnx + sameChainSwap + withdrawCollateral + flagForLiquidation + liquidate + escrowRewards + withdrawRewards;
      return { ...item, ind: ind, transactions: totalTnx, mintTnx, campRewards, sameChainSwap, addCollateral, bridge, burn, withdrawCollateral, flagForLiquidation, liquidate, escrowRewards, withdrawRewards }
    })
    return newUser;
  }

  const BSCTestnetValues = (users) => {
    const newUser = users.map((item, ind) =>  {
      const totalTnx = item.bsc_bridge + item.bsc_burn + item.bsc_collateral + item.bsc_liquidate + item.bsc_liquidation_flag + item.bsc_mint + item.bsc_stake + item.bsc_swap_cross + item.bsc_swap_same;
      const campRewards = item.camp_reward;
      const addCollateral = item.bsc_stake;
      const mintTnx = item.bsc_mint;
      const sameChainSwap = item.bsc_swap_same + item.bsc_swap_cross;
      const bridge = item.bsc_bridge;
      const burn = item.bsc_burn;
      const withdrawCollateral = item.bsc_collateral;
      const flagForLiquidation = item.bsc_liquidation_flag;
      const liquidate = item.bsc_liquidate;
      const escrowRewards = 0;
      const withdrawRewards = 0;
      return { ...item, ind: ind, transactions: totalTnx, mintTnx, campRewards, sameChainSwap, addCollateral, bridge, burn, withdrawCollateral, flagForLiquidation, liquidate, escrowRewards, withdrawRewards }
    })
    return newUser;
  }

  const FujiChainValues = (users) => {
    const newUser = users.map((item, ind) =>  {
      const totalTnx = item.fuji_bridge + item.fuji_burn + item.fuji_collateral + item.fuji_liquidate + item.fuji_liquidation_flag + item.fuji_mint + item.fuji_stake + item.fuji_swap_cross + item.fuji_swap_same;
      const campRewards = item.camp_reward;
      const addCollateral = item.fuji_stake;
      const mintTnx = item.fuji_mint;
      const sameChainSwap = item.fuji_swap_same + item.fuji_swap_cross;
      const bridge = item.fuji_bridge;
      const burn = item.fuji_burn;
      const withdrawCollateral = item.fuji_collateral;
      const flagForLiquidation = item.fuji_liquidation_flag;
      const liquidate = item.fuji_liquidate;
      const escrowRewards = 0;
      const withdrawRewards = 0;
      return { ...item, ind: ind, transactions: totalTnx, campRewards, mintTnx, sameChainSwap, addCollateral, bridge, burn, withdrawCollateral, flagForLiquidation, liquidate, escrowRewards, withdrawRewards }
    })
    return newUser;
  }

  const goereliTestnetValues = (users) => {
    const newUser = users.map((item, ind) =>  {
      const totalTnx = item.goerli_bridge + item.goerli_burn + item.goerli_collateral + item.goerli_liquidate + item.goerli_liquidation_flag + item.goerli_mint + item.goerli_stake + item.goerli_swap_cross + item.goerli_swap_same;
      const campRewards = item.camp_reward;
      const addCollateral = item.goerli_stake;
      const mintTnx = item.goerli_mint;
      const sameChainSwap = item.goerli_swap_same + item.goerli_swap_cross;
      const bridge = item.goerli_bridge;
      const burn = item.goerli_burn;
      const withdrawCollateral = item.goerli_collateral;
      const flagForLiquidation = item.goerli_liquidation_flag;
      const liquidate = item.goerli_liquidate;
      const escrowRewards = 0;
      const withdrawRewards = 0;
      return { ...item, ind: ind, transactions: totalTnx, campRewards, mintTnx, sameChainSwap, addCollateral, bridge, burn, withdrawCollateral, flagForLiquidation, liquidate, escrowRewards, withdrawRewards }
    })
    return newUser;
  }

  const polygonMumbaiValues = (users) => {
    const newUser = users.map((item, ind) =>  {
      const totalTnx = item.mumbai_bridge + item.mumbai_burn + item.mumbai_collateral + item.mumbai_liquidate + item.mumbai_liquidation_flag + item.mumbai_mint + item.mumbai_stake + item.mumbai_swap_cross + item.mumbai_swap_same;
      const campRewards = item.camp_reward;
      const addCollateral = item.mumbai_stake;
      const mintTnx = item.mumbai_mint;
      const sameChainSwap = item.mumbai_swap_same + item.mumbai_swap_cross;
      const bridge = item.mumbai_bridge;
      const burn = item.mumbai_burn;
      const withdrawCollateral = item.mumbai_collateral;
      const flagForLiquidation = item.mumbai_liquidation_flag;
      const liquidate = item.mumbai_liquidate;
      const escrowRewards = 0;
      const withdrawRewards = 0;
      return { ...item, ind: ind, transactions: totalTnx, campRewards, mintTnx, sameChainSwap, addCollateral, bridge, burn, withdrawCollateral, flagForLiquidation, liquidate, escrowRewards, withdrawRewards }
    })
    return newUser;
  }

  const metisValues = (users) => {
    const newUser = users.map((item, ind) =>  {
      const totalTnx = item.metis_bridge + item.metis_burn + item.metis_stake + item.metis_swap_cross + item.metis_swap_same;
      const campRewards = item.camp_reward;
      const addCollateral = item.metis_stake;
      const mintTnx = 0;
      const sameChainSwap = item.metis_swap_same + item.metis_swap_cross;
      const bridge = item.metis_bridge;
      const burn = item.metis_burn;
      const withdrawCollateral = 0;
      const flagForLiquidation = 0;
      const liquidate = 0;
      const escrowRewards = 0;
      const withdrawRewards = 0;
      return { ...item, ind: ind, transactions: totalTnx, campRewards, mintTnx, sameChainSwap, addCollateral, bridge, burn, withdrawCollateral, flagForLiquidation, liquidate, escrowRewards, withdrawRewards }
    })
    return newUser;
  }

  const sepoliaValues = (users) => {
    const newUser = users.map((item, ind) =>  {
      const campRewards = item.camp_reward;
      const addCollateral = item.sepolia_stake;
      const mintTnx = item.sepolia_mint;
      const sameChainSwap = item.sepolia_swap_same + item.sepolia_swap_cross;
      const bridge = item.sepolia_bridge;
      const burn = item.sepolia_burn;
      const withdrawCollateral = item.sepolia_collateral;
      const flagForLiquidation = item.sepolia_liquidation_flag;
      const liquidate = item.sepolia_liquidate;
      const escrowRewards = 0;
      const withdrawRewards = 0;
      const totalTnx = bridge + burn + addCollateral + mintTnx + sameChainSwap + withdrawCollateral + flagForLiquidation + liquidate + escrowRewards + withdrawRewards;
      // const totalTnx = item.sepolia_bridge + item.sepolia_burn + item.sepolia_collateral + item.sepolia_liquidate + item.sepolia_liquidation_flag + item.sepolia_mint + item.sepolia_stake + item.sepolia_swap_cross + item.sepolia_swap_same;
      return { ...item, ind: ind, transactions: totalTnx, mintTnx, campRewards, sameChainSwap, addCollateral, bridge, burn, withdrawCollateral, flagForLiquidation, liquidate, escrowRewards, withdrawRewards }
    })
    return newUser;
  }

  const ArbitrumSepoliaValues = (users) => {
    const newUser = users.map((item, ind) =>  {
      const campRewards = item.camp_reward;
      const addCollateral = item.arbsepolia_stake;
      const mintTnx = item.arbsepolia_mint;
      const sameChainSwap = item.arbsepolia_swap_same + item.arbsepolia_swap_cross;
      const bridge = item.arbsepolia_bridge;
      const burn = item.arbsepolia_burn;
      const withdrawCollateral = item.arbsepolia_collateral;
      const flagForLiquidation = item.arbsepolia_liquidation_flag;
      const liquidate = item.arbsepolia_liquidate;
      const escrowRewards = item.arbsepolia_escrow;
      const withdrawRewards = item.arbsepolia_withdraw;
      const totalTnx = bridge + burn + addCollateral + mintTnx + sameChainSwap + withdrawCollateral + flagForLiquidation + liquidate + escrowRewards + withdrawRewards;
      return { ...item, ind: ind, transactions: totalTnx, mintTnx, campRewards, sameChainSwap, addCollateral, bridge, burn, withdrawCollateral, flagForLiquidation, liquidate, escrowRewards, withdrawRewards }
    })
    return newUser;
  }

  const PolygonAmoyValues = (users) => {
    const newUser = users.map((item, ind) =>  {
      const bridge = item.amoy_bridge;
      const burn = item.amoy_burn;
      const withdrawCollateral = item.amoy_collateral;
      const liquidate = item.amoy_liquidate;
      const flagForLiquidation = item.amoy_liquidation_flag;
      const mintTnx = item.amoy_mint;
      const addCollateral = item.amoy_stake;
      const campRewards = item.camp_reward;
      const sameChainSwap = item.amoy_swap_same + item.amoy_swap_cross;
      const escrowRewards = 0;
      const withdrawRewards = 0;
      const totalTnx = bridge + burn + addCollateral + mintTnx + sameChainSwap + withdrawCollateral + flagForLiquidation + liquidate + escrowRewards + withdrawRewards;
      return { ...item, ind: ind, transactions: totalTnx, mintTnx, campRewards, sameChainSwap, addCollateral, bridge, burn, withdrawCollateral, flagForLiquidation, liquidate, escrowRewards, withdrawRewards }
    })
    return newUser;
  }

    // const getReferrals = async () => {
  //   setReferralsLoader(true);
  // }

  // const getCampaignLeaderboard = async () => {
  //   await syCreditsService.getCampaignLeaderboard().then((res) => {
  //     const data = res?.data;
  //     if(res.status === 200 && data.success){
  //       const top_users = data?.data?.top_users;
  //       const newUsers = top_users.map((item, ind) =>  {
  //         const totalTnx = item.num_burn_same + item.num_mint_same + item.num_swap_same + item.num_addcol_same + item.num_mint_cross + item.num_swap_cross + item.num_liquid_position + item.num_liquid_flag;
  //         const mintTnx = item.num_mint_cross + item.num_mint_same;
  //         const swapTnx = item.num_swap_cross + item.num_swap_same;
  //         return { ...item, ind: ind, transactions: totalTnx, mintTnx, swapTnx }
  //       })
  //       if(newUsers.length > 0) {
  //         setCampaignTopUsers(newUsers);
  //         setCampaignFilteredTopUser(newUsers)
  //       }
  //     }
  //   })
  //   .catch((err) => {
  //     alert(err);
  //   });
  // }

  // const handleChangeTab = (item) => {
  //   setActiveTab(item); 
  //   setShowTabs(!showTabs);
  //   if(item === 'Leaderboard' && filtredTopUsers.length === 0){
  //     getLeaderboard();
  //   } 
  //   // else if(item === 'Logs' && filteredLogs.length === 0){
  //   //   getLogsDetails();
  //   // }
  // }

  // const getLogsDetails = async () => {
  //   if(apiCallCount === 0){
  //     setLogsLoader(true)
  //   }
  //   await syCreditsService.getLogs().then((res) => {
  //     let callCount = apiCallCount + 1;
  //     setApiCallCount(callCount);

  //     const data = res?.data;
  //     if(data.success){
  //       setLogs(data.data.logs);
  //       setFilteredLogs(data.data.logs);
  //       console.log(data.data.logs.length);
  //     }
  //   })
  //   .catch((err) => {
  //     alert(err);
  //   });
  //   setLogsLoader(false)
  // }

  return (
    <section className="table-section" id="sy-credits">
      <TwitterCard 
        data={filteredTransactionHistory}
        element={elementRef}
        dateAndTime={timeForCard}
        showCard={visibleCard}
        testnetUsers={totalTestnetUser}
        testnetTnx={totalTnx}
      />
      <div className="table-container">
        <div className="site-container">
          
          <div className="searchbar-px">
            <div className="social-proofing-banner">
              <div className="title">
                Social proofing
              </div>
              <div className="description">
                Qualify for syCREDITS by completing 1 on-chain transaction and performing 1 Zealy quest.
              </div>
            </div>
          </div>

          <div className="d-md-flex align-items-center searchbar-px">
            <div 
              className='sycredits-hamburger' 
              onClick={() => setShowTableNavbar(!showTableNavbar)}
            >
              {
                showTableNavbar ?
                  <img src={Cross} alt='' />
                :
                <>
                  <div></div>
                  <div></div>
                </>
              }
            </div>

            <div className="search-input-wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M17.9417 17.0583L14.7409 13.8575C15.8109 12.5883 16.4583 10.9525 16.4583 9.16667C16.4583 5.14583 13.1875 1.875 9.16667 1.875C5.14583 1.875 1.875 5.14583 1.875 9.16667C1.875 13.1875 5.14583 16.4583 9.16667 16.4583C10.9525 16.4583 12.5884 15.8108 13.8575 14.7408L17.0583 17.9417C17.18 18.0633 17.34 18.125 17.5 18.125C17.66 18.125 17.82 18.0642 17.9417 17.9417C18.1859 17.6983 18.1859 17.3025 17.9417 17.0583ZM3.125 9.16667C3.125 5.835 5.835 3.125 9.16667 3.125C12.4983 3.125 15.2083 5.835 15.2083 9.16667C15.2083 12.4983 12.4983 15.2083 9.16667 15.2083C5.835 15.2083 3.125 12.4983 3.125 9.16667Z" fill="#BBBBBB"/>
              </svg>
              <input 
                type="text"
                value={searchValue}
                onChange={(e) => onHandleSearch(e.target.value)}
                placeholder="Search using wallet address"
                disabled={activeTab === 'Logs' || activeTab === 'Zealy XP leaderboard'}
              />
            </div>

            {
              activeTab === 'syCREDITS leaderboard' ?
                <button 
                  className="share-leaderboard-button" 
                  onClick={() => {setVisibleCard(true); generateImage()}}
                  disabled={searchValue === '' || filtredTopUsers === null || filtredTopUsers.length !== 1}
                >
                  GENERATE SCORECARD
                </button>
              :
                ''
            }
            {
              activeTab === 'Transaction history' ?
              <div className="filter-chain-container">
                <div className='select-chain-wrapper'>
                  <div className='title-container'>
                    Filter chain<br />
                    <div>{chain !== '' ? chain : ''}</div>
                  </div>
                    <div className='links-wrapper'>
                      {
                        chains.map((item, ind) => (
                          <div className={`chain-container ${item === chain ? 'active-chain' : ''}`} key={`${item}-${ind}`} onClick={() => setChain(item)}>
                            {item}
                          </div>
                        ))
                      }
                    </div>
                </div>
              </div>
              :
              null
            }
          </div>

          <div className="d-md-flex align-items-start justify-content-end position-relative overflow-hidden">
            <div className={`table-navbar ${showTabs ? 'active' : ''} ${showTableNavbar ? 'show' : ''}`} >
              <div
                className={`tab selected-tab border-none mb-1 d-flex d-md-none`} 
                onClick={() => {setShowTabs(!showTabs)}}
              > 
                <div className="tab-icon-wrapper">
                  {activeTab === 'syCREDITS leaderboard' && <LeaderboardIcon className='active-icon' />}
                  {activeTab === 'Zealy XP leaderboard' && <ZealyLeaderboardIcon className='active-icon' /> }
                  {activeTab === 'Transaction history' && <TransactionHistoryIcon className='active-icon' />}
                </div>
                {activeTab}
              </div>
              {TableNavbar.map((item, ind) => (
                <div
                  className={`tab ${activeTab === item ? 'selected-tab border-none' : ''}`} 
                  onClick={() => {setActiveTab(item); setShowTabs(!showTabs)}}
                  key={ind}
                >
                  <div className="tab-icon-wrapper">
                    {ind === 0 && <LeaderboardIcon className={`${activeTab === 'syCREDITS leaderboard' ? 'active-icon' : ''}`} />}
                    {ind === 1 && <ZealyLeaderboardIcon className={`${activeTab === 'Zealy XP leaderboard' ? 'active-icon' : ''}`} /> }
                    {ind === 2 && <TransactionHistoryIcon className={`${activeTab === 'Transaction history' ? 'active-icon' : ''}`} />}
                    {/* {ind === 0 && <CampaignLeaderboardIcon/>} */}
                    {/* {ind === 2 && <ReferralsIcon />} */}
                    {/* {ind === 2 && <LogsIcon />} */}
                  </div>
                  {item}
                </div>
              ))}
            </div>

            <div className={`tableX ${showTableNavbar ? '' : 'big'}`}>
              {
                (activeTab === "syCREDITS leaderboard") &&
                <Leaderboard 
                  users={filtredTopUsers}
                  setUsers={setFilteredTopUser}
                  updatedDateAndTime={currentDateAndTimeForLeaderboard}
                  loading={leaderboardLoading}
                  showNavbar={showTableNavbar}
                />
              }
              {
                activeTab === "Transaction history" &&
                <TransactionHistory 
                  users={filteredTransactionHistory}
                  setUsers={setFilteredTransactionHistory}
                  updatedDateAndTime={currentDateAndTimeForLeaderboard}
                  loading={leaderboardLoading}
                  showNavbar={showTableNavbar}
                />
              }
              {
                activeTab === 'Zealy XP leaderboard' &&
                <ZealyLeaderboard 
                  users={zealyTopUsers}
                  setUsers={setZealyTopUsers}
                  loading={zealyLeaderboardLoading}
                  showNavbar={showTableNavbar}
                  updatedDateAndTime={currentDateAndTimeForLeaderboard}
                />
              }

              {/* {
                activeTab === 'Referrals' &&
                <Referrals 
                  data={referrals}
                  isLoading={referralsLoader}
                />
              } */}
              {/* {
                (activeTab === "Campaign Leaderboard") &&
                <Leaderboard 
                  users={campaignFilteredTopUser}
                  setUsers={setCampaignFilteredTopUser}
                  leaderboard={activeTab}
                />
              } */}
              {/* {
                activeTab === "Logs" &&
                <Logs 
                  data={filteredLogs}
                  isLoading={logsLoader}
                  updatedDateAndTime={currentDateAndTimeForLogs}
                />
              } */}
            </div>
          </div>
        </div>

        <Footer bg='black' />
      </div>
    </section>
  )
}
export default TableSection;